<template> 
  <card
    v-model="item"
    @openAuth="$emit('openAuth')"
    @delete="$emit('delete')"
    @refresh="$emit('refresh')"
    @getExternalLink="$emit('getExternalLink')"
  >
    <template #header>
      <b-row align-v="center" class="d-flex flex-nowrap justify-content-between w-100">

<!-- Generator: Adobe Illustrator 19.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg class="contaazul-svg-logo-header" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 250 1250 150">
          <path class="st0" d="M137.9,303.2c-20.2,0-33.3,15.1-33.3,32.6c0.1,18.2,12.6,32.6,33.3,32.6c20,0,33.1-15.3,33.1-32.7
            C170.9,317.5,158.3,303.2,137.9,303.2z M137.8,353c-10.4,0-15.2-8.4-15.2-17.3c0-8.8,4.8-17.2,15.3-17.2c10.4,0,15.1,8.3,15.1,17.2
            C153,344.6,148.3,353,137.8,353z M96.4,347.6l-8.9-6.8c-4.2,6.4-10.9,10.7-20.1,10.7c-14.2,0-22.3-11.2-22.3-25.5
            c0-13.9,7.4-25.5,22.7-25.5c8.9,0,15,4,19,10.4l9-6.9c2-1.6,2.5-4.6,1-6.7c-6.9-9.5-17.5-13.6-29.2-13.6c-28,0-41,21-41,42.2
            c0,25.2,15,42.4,40.8,42.4c12.4,0,23.2-4.4,30-14.1C98.9,352.1,98.4,349.2,96.4,347.6z M217.9,303.2c-8.9,0-16,3.8-20.7,10
            c-0.2-1.7-0.4-3.4-0.8-5.2c-0.8-2.2-2.5-3.3-5.2-3.3h-10.5V367h17.9v-29.7c0-12.6,6-18.8,15-18.8c7.1,0,11,4.2,11,12.9v35.5h17.9
            V332C242.6,312.8,233.6,303.2,217.9,303.2z M287.9,352.5c-1,0.3-2.1,0.5-3.2,0.5c-7.2,0-11.3-4.2-11.3-13.1v-20.3h16.1
            c2.7,0,4.8-2.2,4.8-4.8v-10.2h-20.9v-9c0-2.7-2.2-4.8-4.8-4.8h-13.1v48.4c0,18,8,29.1,23.8,29.1c9.3,0,15.8-4.1,18.8-6.3l-4.7-7.4
            C292.2,352.7,290,351.9,287.9,352.5z M333.7,303.2c-9.9,0-17.4,1.9-24.9,6.9c-2.5,1.7-2.9,5.1-1,7.4l5,6.2
            c6.6-5.1,12.1-6.2,18.5-6.2c8.4,0,13.9,4.5,13.9,14.2v0.8c-5-1.9-10.7-2.8-16.9-2.8c-18.1,0-25.5,9.7-25.5,19.7
            c0,9.7,6.4,19,22.7,19c10.4,0,17.3-4.5,21.5-10.2c0.2,1.7,0.3,3.4,0.7,5.2c0.5,2.5,2.5,3.6,5.2,3.6h9.3v-34.4
            C362.1,312,349.6,303.2,333.7,303.2z M344.6,343.4c-1,6.7-6.4,11.8-16.2,11.8c-5.4,0-9.1-2.1-9.1-6.9c0-5,3.6-7.4,11.9-7.4
            c5,0,9.1,0.7,13.4,1.7L344.6,343.4z M521.8,352h-28.3l29.4-32.2c1.7-1.9,2.4-3.6,2.4-6v-4.2v-4.9h-48.4c-3,0-4.8,1.7-4.8,4.8v10.1
            h31l-29.3,32.2c-1.7,1.7-2.5,3.4-2.5,5.9v9.2h55.5v-10.1C526.7,353.7,524.8,352,521.8,352z M591.7,304.6H578v29.8
            c0,12.6-5.7,18.6-14.6,18.6c-6.9,0-10.7-4.2-10.7-12.7v-31.5c0-2.4-1.9-4.2-4.2-4.2h-13.7v35c0,18.9,8.8,28.8,24.3,28.8
            c8.6,0,15.5-3.8,20.3-9.8c0.2,1.6,0.4,3.4,0.8,5c0.7,2.4,2.5,3.4,5.2,3.4H596v-58.1C596,306.5,594.1,304.6,591.7,304.6z
            M621.8,285.1h-13.1v77c0,2.7,2.2,4.8,4.8,4.8h13.1v-77C626.6,287.3,624.4,285.1,621.8,285.1z M431.6,285.1h-16.8L382.9,367h18.4
            l6.9-19.5h33.3l7.1,19.5h18.9l-30.3-78.2C436.3,286.2,434.3,285.1,431.6,285.1z M413.1,332l11.7-31.5l11.7,31.5H413.1z"/>
        </svg>

        
        <h2 class="px-1 d-flex flex-nowrap">
          <span>
          </span>
        </h2>
        <feather-icon
          :icon="active ? 'CheckIcon' : 'XIcon'"
          :class="active ? 'text-primary' : 'text-danger'" 
          size="30"
        />
      </b-row>
    </template>
  </card>
</template>

<script>
import { BImg, BIcon, BRow, BCol } from 'bootstrap-vue'
import Card from '@/layouts/components/ThirdPartyApps/Card.vue'

  export default {
    components: {
      BImg,
      BIcon,
      BRow,
      BCol,
      Card
    },
    props: {
      value: {
        type: Object,
        default: undefined,
      }
    },
    computed: {
      item: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      active() {
        return this.item.token
      }
    }
  }
</script>

<style lang="scss" scoped>
.contaazul-svg-logo-header{
  display: inline;
  max-height: 3em;
}
.st0{fill:#2687E9;}

</style>